@import './node_modules/@sbt-suite/components/theme/variables.scss';
@import './node_modules/@sbt-suite/components/theme/globalClasses.scss';
@import './theme/functions.scss';

:root{
    --primary-color: #9b00ff;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;

    * {
        box-sizing: border-box;
    }
}
.grecaptcha-badge{
    visibility: hidden;
}

.loading-input{
    animation: progress 600ms infinite cubic-bezier(0.44, 0.38, 0.74, 0.74);
}
@keyframes progress {
    100% {
        transform: rotate(360deg);
    }
}
.key-value__value{
    word-break: break-word;
}

.fw-500{
    font-weight: 500;
}